import React from 'react';
import plvtform_logo from '../images/plvtform_logo.png';
import {useNavigate} from 'react-router-dom';

function Landing() {
  const navigate = useNavigate();

  const onClickStart = () => {
    const path = `/home`;
    navigate(path);
  };

  return (
    <>
      <div
        id="plvtform-tube-border"
        class="plvtform-tube-border plvtform-tube-border-mobile"></div>
      <div class="plvtform-tube-shape animate-turn-on">
        <div id="plvtform-content" class="plvtform-content">
          <div class="landing-content">
            <div id="landing-title" class="landing-title">
              <img src={plvtform_logo} class="landing-image" alt="logo" />
            </div>
            <div class="landing-start">
              <div class="landing-start-container">
                <h2 id="landing-start-button" class="landing-start-text">
                  <span class="landing-start-arrow">›</span>
                  <button onClick={onClickStart}>START PLVTFORM</button>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;

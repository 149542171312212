import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import HomeImg from '../images/home.gif';
import {useNavigate} from 'react-router-dom';

function Contact() {
  const navigate = useNavigate();

  const [sendEmail, setSendEmail] = useState(false);

  const form = useRef();

  const onSendEmail = e => {
    e.preventDefault();
    emailjs
      .sendForm('service_ka75tq7', 'template_jn13ax8', form.current, {
        publicKey: 'biN4wRyKghnsccGzY',
      })
      .then(
        response => {
          console.log('SUCCESS!', response.status, response.text);
          setSendEmail(true);
        },
        error => {
          console.log('FAILED...', error);
        },
      );
  };

  const onClickCloseModal = () => {
    setSendEmail(false);
    window.location.reload();
  };

  const onHomeClick = () => {
    navigate('/home');
  };

  return (
    <>
      <div
        id="plvtform-tube-border "
        class="plvtform-tube-border plvtform-tube-border-mobile"></div>

      <div
        id="plvtform-content"
        className="plvtform-content"
        style={{
          animation: '3.4s linear 0s 1 normal none running turn-on',
          height: '100vh',
        }}>
        <button
          id="plvtform-home"
          className="plvtform-home"
          onClick={onHomeClick}>
          <img src={HomeImg} alt="Home button spinning globe" />
        </button>
        <div className="contact-content">
          <div className="plvtform-stars" />

          <div className="contact-container">
            <div className="contact-navbar-decoration">
              <svg className="contact-navbar-svg">
                <line
                  className="contact-navbar-line"
                  x1={0}
                  y1={0}
                  x2="100%"
                  y2={0}
                />
                <line
                  className="contact-navbar-line"
                  x1={0}
                  y1="33%"
                  x2="100%"
                  y2="33%"
                />
                <line
                  className="contact-navbar-line"
                  x1={0}
                  y1="66%"
                  x2="100%"
                  y2="66%"
                />
                <line
                  className="contact-navbar-line"
                  x1={0}
                  y1="100%"
                  x2="100%"
                  y2="100%"
                />
                <rect
                  className="contact-navbar-square-outer"
                  height="100%"
                  width="30px"
                  x="4%"
                />
                <rect
                  className="contact-navbar-square-inner"
                  height="100%"
                  width="30px"
                  x="4%"
                />
              </svg>
            </div>

            <div className="contact-navbar">
              <h1 className="contact-navbar-text">
                <span className="contact-navbar-text-extra">CONTACT</span>
              </h1>
            </div>

            <form className="contact-contact" onSubmit={onSendEmail} ref={form}>
              <div className="contact-email-container">
                <div className="contact-contact-label-container">
                  <div className="contact-contact-label">
                    <h1 className="contact-contact-label-text">EMAIL</h1>
                  </div>
                </div>
                <div className="contact-contact-input-container">
                  <textarea
                    className="contact-contact-textarea contact-contact-textarea-mobile rainbow-text"
                    placeholder="Email"
                    id="contact-email"
                    name="from_email"
                    style={{
                      overflow: 'hidden',
                      overflowWrap: 'break-word',
                      height: 57,
                    }}
                    defaultValue={''}
                  />
                </div>
              </div>
              <div className="contact-subject-container">
                <div className="contact-contact-label-container">
                  <div className="contact-contact-label">
                    <h1 className="contact-contact-label-text">Name</h1>
                  </div>
                </div>
                <div className="contact-contact-input-container">
                  <textarea
                    className="contact-contact-textarea contact-contact-textarea-mobile rainbow-text"
                    placeholder="Name"
                    id="contact-subject"
                    style={{
                      overflow: 'hidden',
                      overflowWrap: 'break-word',
                      height: 57,
                    }}
                    defaultValue={''}
                    name="from_name"
                  />
                </div>
              </div>
              <div className="contact-message-container">
                <div className="contact-contact-label-container">
                  <div className="contact-contact-label">
                    <h1 className="contact-contact-label-text">MESSAGE</h1>
                  </div>
                </div>
                <div className="contact-contact-textarea-container">
                  <textarea
                    className="contact-contact-textarea contact-contact-textarea-mobile rainbow-text"
                    placeholder="Message"
                    id="contact-message"
                    name="message"
                    style={{
                      overflow: 'hidden',
                      overflowWrap: 'break-word',
                      height: 122,
                    }}
                    defaultValue={''}
                  />
                </div>
              </div>
              <div className="contact-footer">
                <button
                  className="contact-footer-text"
                  id="contact-send-plvtform"
                  type="submit">
                  SEND TO
                  <br />
                  PLVTFORM
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {sendEmail && (
        <div class="modal-overlay modal-opened">
          <div class="modal-modal notify-modal modal-opened" id="notify-modal">
            <h1 id="notify-message" class="notify-message">
              MESSAGE SENT TO PLVTFORM
            </h1>
            <br></br>
            <h3>
              <button
                class="notify-confirm modal-close"
                data-modal="#notify-modal"
                onClick={onClickCloseModal}>
                OK
              </button>
            </h3>
          </div>
        </div>
      )}
    </>
  );
}

export default Contact;

import React from 'react';
import plvtform_logo from '../images/plvtform_logo.png';
import {useNavigate} from 'react-router-dom';
import questionImg from '../images/question-block-red.gif';
import pager from '../images/pager.gif';
import forum from '../images/forum.gif';

const Home = () => {
  const navigate = useNavigate();

  const onClickNavigate = path => {
    navigate(path);
  };

  return (
    <>
      <div
        id="plvtform-tube-border"
        class="plvtform-tube-border plvtform-tube-border-mobile"></div>
      <div className="plvtform-tube-shape">
        <div
          id="plvtform-content"
          className="plvtform-content"
          style={{animation: '3.4s linear 0s 1 normal none running turn-on'}}>
          <div className="home-content">
            <div className="home-container">
              <div className="home-video-container"></div>
              <div className="home-navbar">
                <img
                  src={plvtform_logo}
                  onclick="newPage('/')"
                  style={{height: 35}}
                />
              </div>
              <div className="home-options-container">
                <div className="home-options">
                  <div className="home-options-row">
                    <div className="home-options-item">
                      <div className="home-options-shop-content">
                        <button
                          id="forums-button"
                          class="home-options-wrapper forums-button"
                          onClick={() => onClickNavigate('/articles')}>
                          <img
                            class="home-options-icon"
                            src={forum}
                            alt="Forum button Profile spinning icon"
                          />
                          <h3 class="home-options-text home-options-text-forum">
                            Articles
                          </h3>
                        </button>
                      </div>
                    </div>

                    <div className="home-options-item">
                      <div className="home-options-about-content">
                        <button
                          id="about-button"
                          className="home-options-wrapper about-button"
                          onClick={() => onClickNavigate('/images')}>
                          <img
                            className="home-options-icon"
                            src={questionImg}
                            alt="About button Spinning mystery box icon"
                          />
                          <h3 className="home-options-text home-options-text-red">
                            IMAGES
                          </h3>
                        </button>
                      </div>
                    </div>
                    <div className="home-options-item">
                      <div className="home-options-contact-content">
                        <button
                          id="contact-button"
                          className="home-options-wrapper contact-button"
                          onClick={() => onClickNavigate('/contact')}>
                          <img
                            className="home-options-icon"
                            src={pager}
                            alt="About button Spinning pager icon"
                          />
                          <h3 className="home-options-text home-options-text-green">
                            CONTACT
                          </h3>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="plvtform-copyright">
                <h4 className="plvtform-copyright-text">
                  ©&nbsp;&nbsp;2024&nbsp;&nbsp;PLVTFORM
                  <br />
                  <span className="plvtform-tc plvtform-terms">
                    <button>Terms and Conditions</button>
                  </span>
                  <br />
                  <span className="plvtform-tc plvtform-privacy">
                    <button>Privacy Policy</button>
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className="home-content-mobile">
            <div className="home-navbar home-navbar-mobile">
              <img
                src="https://dwvo2npct47gg.cloudfront.net/images/awge-halftone-logo.png"
                onclick="newPage('home')"
                alt="Home button AWGE logo"
              />
            </div>
            <div className="home-container-mobile">
              <div className="home-section-button-mobile">
                <div className="home-section-button-row-mobile">
                  <button
                    id="forums-button"
                    className="home-options-wrapper forums-button"
                    onClick={() => onClickNavigate('/articles')}>
                    <img
                      style={{maxHeight: '9vh'}}
                      className="home-options-icon"
                      src={forum}
                      alt="Forum button Profile spinning icon"
                    />
                    <h3 className="home-options-text home-options-text-forum">
                      ARTICLES
                    </h3>
                  </button>
                </div>

                <div className="home-section-button-row-mobile">
                  <button
                    id="about-button"
                    className="home-options-wrapper about-button"
                    onClick={() => onClickNavigate('/images')}>
                    <img
                      className="home-options-icon"
                      src={questionImg}
                      alt="About button Spinning mystery box icon"
                    />
                    <h3 className="home-options-text home-options-text-red">
                      IMAGES
                    </h3>
                  </button>
                </div>

                <div className="home-section-button-row-mobile">
                  <button
                    id="contact-button"
                    className="home-options-wrapper contact-button"
                    onClick={() => onClickNavigate('/contact')}>
                    <img
                      style={{maxHeight: '8.5vh'}}
                      className="home-options-icon"
                      src={pager}
                      alt="About button Spinning pager icon"
                    />
                    <h3 className="home-options-text home-options-text-green">
                      CONTACT
                    </h3>
                  </button>
                </div>
              </div>
              <div className="home-section-copyright-mobile">
                <h4 className="awge-copyright-text">
                  ©&nbsp;&nbsp;2024&nbsp;&nbsp;PLVTFORM <br />
                  <span className="awge-tc awge-terms">
                    <button>Terms and Conditions</button>
                  </span>
                  <br />
                  <span className="awge-tc awge-privacy">
                    <button>Privacy Policy</button>
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

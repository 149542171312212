import {Routes, Route} from 'react-router-dom';
import './App.scss';
import Landing from './pages/Landing';
import Home from './pages/Home';
import Images from './pages/Images';
import Articles from './pages/Articles';
import Artist from './pages/Artist';
import Contact from './pages/Contact';
import {useEffect} from 'react';
import emailjs from '@emailjs/browser';

function App() {
  useEffect(() => {
    emailjs.init({
      publicKey: 'biN4wRyKghnsccGzY',
      blockHeadless: true,
      limitRate: {
        throttle: 10000, // 10s
      },
    });
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="home" element={<Home />} />
      <Route path="images" element={<Images />} />
      <Route path="articles" element={<Articles />} />
      <Route path="artist" element={<Artist />} />
      <Route path="contact" element={<Contact />} />
    </Routes>
  );
}

export default App;

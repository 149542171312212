import React from 'react';

function Articles() {
  const {innerWidth: width, innerHeight: height} = window;

  return (
    <>
      <div
        id="plvtform-tube-border"
        class="plvtform-tube-border plvtform-tube-border-mobile"></div>
      <p
        align="center"
        style={{
          marginTop: 65,
          marginBottom: 45,
          animation: '3.4s linear 0s 1 normal none running turn-on',
        }}>
        <iframe
          height={height}
          width="85%"
          src="https://www.joopiter.com/archive"></iframe>
      </p>
    </>
  );
}

export default Articles;

import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import HomeImg from '../images/home.gif';

function Artist() {
  const navigate = useNavigate();

  const {state} = useLocation();

  const {images} = state;

  const onClickHome = () => {
    navigate('/home');
  };

  return (
    <div>
      <div
        id="plvtform-tube-border"
        class="plvtform-tube-border plvtform-tube-border-mobile"></div>
      <div className="plvtform-tube-shape">
        <div
          id="plvtform-content"
          className="plvtform-content"
          style={{animation: '3.4s linear 0s 1 normal none running turn-on'}}>
          <button
            id="plvtform-home"
            className="plvtform-home"
            onClick={onClickHome}>
            <img src={HomeImg} alt="Home button spinning globe" />
          </button>
          <div className="home-navbar home-navbar-mobile"></div>
          <div className="shop">
            <section className="shop-header">
              <div className="shop-header-logo"></div>
            </section>
            <section className="shop-container">
              <div className="shop-container-grid">
                {images.map(image => {
                  return (
                    <div className="shop-container-grid-item">
                      <h2 className="item-price"></h2>
                      <img src={image} />
                    </div>
                  );
                })}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Artist;

import React from 'react';
import {useNavigate} from 'react-router-dom';

import k1 from '../images/kundo/k1.jpeg';
import k2 from '../images/kundo/k2.jpeg';
import k3 from '../images/kundo/k3.jpeg';
import k4 from '../images/kundo/k4.jpeg';
import m1 from '../images/mas/m1.jpg';
import m2 from '../images/mas/m2.jpg';
import m3 from '../images/mas/m3.jpg';
import m4 from '../images/mas/m4.jpg';
import m5 from '../images/mas/m5.jpg';
import m6 from '../images/mas/m6.jpg';

import HomeImg from '../images/home.gif';

const artistes = [
  {
    name: 'Kundo',
    images: [k1, k2, k3, k4],
  },
  {name: 'Mas', images: [m1, m2, m3, m4, m5, m6]},
];

function Images() {
  const navigate = useNavigate();

  const onClickArtist = images => {
    navigate('/artist', {state: {images}});
  };

  const onClickHome = () => {
    navigate('/home');
  };

  return (
    <>
      <div
        id="plvtform-tube-border"
        class="plvtform-tube-border plvtform-tube-border-mobile"></div>
      <div className="plvtform-tube-shape">
        <div
          id="plvtform-content"
          className="plvtform-content"
          style={{animation: '3.4s linear 0s 1 normal none running turn-on'}}>
          <button
            id="plvtform-home"
            className="plvtform-home"
            onClick={onClickHome}>
            <img src={HomeImg} alt="Home button spinning globe" />
          </button>

          <div className="plvtform-space-container">
            <div className="plvtform-space-wrapper">
              {artistes.map(artist => {
                return (
                  <>
                    <h1>
                      <button
                        className="plvtform-space-option"
                        id="plvtformdvd-vol-1"
                        onClick={() => onClickArtist(artist.images)}>
                        {artist.name}
                      </button>
                    </h1>
                    <br />
                    <br />
                    <br />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Images;
